import { Controller } from "@hotwired/stimulus";
import walletService from "../services/WalletService";
import httpService from "../services/HttpService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["coins", "chains", "button", "button2", "down"];

  static values = {
    walletAddress: String,
    subscriptionproductid: Number,
  };

  initialize() {
    this.connected = false;

    this.watchWallet = async (wallets) => {
      if (walletService.isConnected()) {
        if (this.connected == false) {
          this.connected = true;
        }
        this.scanWallet();
      }
    };

    this.scanWallet = async () => {

      let oldAddress = this.walletAddressValue;
      let address = walletService.getWalletAddress();
      this.walletAddressValue = address;


      if (oldAddress != address) {
        debugLog("SCAN WALLET: ", address)
                  
        try {
  
            const walletIntel = await httpService.get(
              `/buy/presign/${address}/${this.subscriptionproductidValue}`
            );
            if (walletIntel.type == "html") {
              await Turbo.renderStreamMessage(walletIntel.data);
            }
  
          } catch (error) {
            debugLog("Error fetching presign endpoint:", error);
          }
  
        }
  

    }


  }

  async selectToken({ params }) {
    let address = walletService.getWalletAddress();
    try {
      let res = await httpService.get(
        `/buy/selecttoken/${params.subprodid}/${params.tokenid}/${params.networkid}/${address}`
      );
      await Turbo.renderStreamMessage(res.data);
    } catch (error) {
      this.dispatch("error", { detail: { content: error.message } });
    }
  }

  connect() {
    walletService.onWalletChange(this.watchWallet);
    let address = walletService.getWalletAddress();
    this.walletAddressValue = address;    
  }

  disconnect() {
    walletService.removeWalletChangeListener(this.watchWallet);
  }

  showChains(event) {
    const tokenId = event.currentTarget.getAttribute("data-token-id");

    // Loop through all chainsTargets to find the one that matches the tokenId
    this.chainsTargets.forEach((chain, index) => {
      if (chain.getAttribute("data-token-id") === tokenId) {
        // Show the corresponding button and chain for the clicked tokenId
        this.buttonTargets[index].classList.add("shadow-xl", "border-2");
        this.buttonTargets[index].classList.remove(
          "shadow",
          "border-transparent"
        );
        chain.classList.remove("hidden");
        this.downTargets[index].classList.remove("hidden");
      } else {
        // Hide other buttons and chains that don't match the clicked tokenId
        this.buttonTargets[index].classList.remove("shadow-xl", "border-2");
        this.buttonTargets[index].classList.add("shadow", "border-transparent");
        chain.classList.add("hidden");
        this.downTargets[index].classList.add("hidden");
      }
    });
  }

  selectChain() {
    this.buttonTarget.classList.remove(
      "shadow-xl",
      "border-2",
      "border-blue-300"
    );
    this.buttonTarget.classList.add("shadow", "border-transparent");
    this.coinsTarget.classList.add("hidden");
    this.chainsTarget.classList.add("hidden");
    this.downTarget.classList.add("hidden");
  }
}
