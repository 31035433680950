import { Controller } from "@hotwired/stimulus";
import { debugLog } from "../utils";

const customDropdownClassName = 'customized-dropdown';

export default class extends Controller {
  static targets = ["button"];

  initialize() {
    debugLog("DropMenu Controller Initialized:");

    this.buttonClick = (e) => {
      e.stopPropagation();
      
      // Check if a dropdown already exists
      // and remove it first.
      if (Boolean(this.activeDropdown)) {
        this.removeActiveDropdown();
      }
      
      // Duplicate dropdown check.
      if (!Boolean(this.activeDropdown)) {
        const id = e.currentTarget.dataset.id;
        const options = JSON.parse(e.currentTarget.dataset.options);
  
        const dropdown = document.createElement('div');
        dropdown.classList.add(customDropdownClassName);
  
        for (let i = 0; i < options.length; i++) {
          const div = document.createElement('div');
          const link = document.createElement('a');
          // These CSS does not work on stylesheet for some reason.
          link.style.display = 'block';
          link.style.width = '100%';
          link.style.padding = '4px 20px';
          link.textContent = options[i].text;
          link.setAttribute('href', options[i].link + id);
          div.appendChild(link);
          dropdown.appendChild(div);
        }
  
        e.currentTarget.appendChild(dropdown);

        this.activeDropdown = e.currentTarget;

        window.addEventListener("click", this.removeActiveDropdown);
      }
    }

    this.removeActiveDropdown = (e) => {
      if (this.activeDropdown) {
        const dropdown = this.activeDropdown.getElementsByClassName(customDropdownClassName)[0];
        dropdown.remove();
        this.activeDropdown = null;
        window.removeEventListener("click", this.removeActiveDropdown);
      }
    }
  }

  connect() {
    debugLog("DropMenu Controller Connected");
    if (this.hasButtonTarget) {
      this.buttonTargets.forEach((button) => {
        button.addEventListener("click", this.buttonClick);
      });
    }
  }

  disconnect() {
    this.buttonTargets.forEach((button) => {
      button.removeEventListener("click", this.buttonClick);
    });    
  }
}
