import { Controller } from "@hotwired/stimulus";
import httpService from "../services/HttpService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    debugLog("ModalContainer Controller Connected");
    document.addEventListener("open-dialog", this.openDialog.bind(this)); // Listen for custom events
  }

  async openDialog(event) {

    const dialogId = event.detail.dialogId;
    const posturl = event.detail.posturl;

    let existingDialogWrapper = this.containerTarget.querySelector(`[data-modal-dialog-id-value="${dialogId}"]`);

    debugLog("OpenDialog Called");
    debugLog("DialogId: ", dialogId);
    debugLog("PostURL: ", posturl); 
    debugLog("Container: ", this.containerTarget);        
    debugLog("Existing Dialog: ", existingDialogWrapper);            

    if (existingDialogWrapper) {
      // Use the existing dialog
      this.openModal(existingDialogWrapper.firstElementChild);
    } else {
      // Fetch the dialog from the server
      let res = await httpService.get(posturl);
      if (res.type == "html") {
        Turbo.renderStreamMessage(res.data);
      }
    }
  }

  openModal(dialog) {
    dialog.classList.remove('hidden');
  }

  openDialogFromButton(event) {
    const button = event.target;
    const dialogId = button.dataset.dialogId;
    const posturl = button.dataset.posturl;

    debugLog("openDialogFromButton Called");
    debugLog("DialogId: ", dialogId);
    debugLog("PostURL: ", posturl); 

    const customEvent = new CustomEvent("open-dialog", {
      detail: {
        dialogId: dialogId,
        posturl: posturl,
      },
      bubbles: true,
    });
    this.element.dispatchEvent(customEvent);
  }
}
