import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["canvas"];

  connect() {
    const revenueData = JSON.parse(this.element.dataset.revenueData);
    const labels = revenueData.map(data => data.date);
    const revenueValues = revenueData.map(data => data.revenue);
    
    new Chart(this.canvasTarget, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Revenue Over Time',
          data:  revenueValues,
          fill: true,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            let gradient;

            if (document.documentElement.classList.contains('dark')) {
              gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(0, 132, 255, .25)');
              gradient.addColorStop(0.85, 'rgba(0, 132, 255, .075)');
              gradient.addColorStop(1, 'rgba(0, 132, 255, .025)');
            } else {
              gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, 'rgba(49, 131, 42, .25)');
              gradient.addColorStop(0.85, 'rgba(49, 131, 42, .075)');
              gradient.addColorStop(1, 'rgba(49, 131, 42, .025)');
            }

            return gradient;
          },
          borderColor: document.documentElement.classList.contains('dark') ? '#0085FF' : 'green',
          borderWidth: 2,
          tension: 0.3,
          pointRadius: 0,
        }],
      },
      options: {
        scales: {
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              width: 0,
            },
          },
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              width: 0,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        layout: {
          padding: 0
        },
        maintainAspectRatio: false,
      }
    });
  }
}
