import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { dialogId: String, posturl: String };

  connect() {
    console.log("Button Controller Connected");
  }

  openDialog(event) {
    event.preventDefault();

    const customEvent = new CustomEvent("open-dialog", {
      detail: {
        dialogId: this.dialogIdValue,
        posturl: this.posturlValue,
      },
      bubbles: true,
    });
    this.element.dispatchEvent(customEvent);
  }
}
