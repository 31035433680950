import { debugError, debugLog } from "../utils";

class HttpService {

 replacer(key, value) {
    if (typeof value === 'bigint') {
        return value.toString();
    }
    return value;
  }

    async get(url) {
      try {
        const response = await fetch(url);
        return await this.processResponse(response);
      } catch (error) {
        // Handle or throw the error as needed
        debugError('GET request error:', error.message);
        throw error;
      }
    }

    async post(url, data) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data, this.replacer),
        });
        return await this.processResponse(response);
      } catch (error) {
        // Handle or throw the error as needed
        debugError('POST request error:', error.message);
        throw error;
      }
    }

    async postWithTimeout(url, data, timeout = 1000) {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
          const response = await fetch(url, {
            method: 'POST',
            signal: controller.signal,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          });
          clearTimeout(timeoutId);
          return await this.processResponse(response);
        } catch (error) {
          clearTimeout(timeoutId);
          // Handle or throw the error as needed
          debugError('POST request with timeout error:', error.message);
          throw error;
        }
      }

    async processResponse(response) {
      if (!response.ok) {

        const errorBody = await response.json();

        if (errorBody.type && errorBody.type=="html") {
          return errorBody; //sent html error back
        }

        const errorMessage = errorBody.message || 'Unknown error occurred';
        throw new Error(errorMessage);
      }
      return await response.json();
    }
  }

  const httpService = new HttpService();
  export default httpService;