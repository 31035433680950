import { Controller } from '@hotwired/stimulus';
import { debugLog } from '../utils';

export default class extends Controller {
  static targets = ['link'];

  initialize() {
    document.addEventListener('turbo:before-render', async (event) => {
      //debugLog('Before Render:', event)
      this.adjustLinksByURL();
    });

    this.activeLink = null;
    this.urls = [];
    this.links = {};
    this.updateNav = async (event) => {
      // debugLog('Before Visit:', event)
    }
  }

  connect() {
    // debugLog('Navigation Controller Connected!')
    document.addEventListener('turbo:before-visit', this.updateNav)   
    if (this.hasLinkTarget) {
      this.linkTargets.forEach(element => {
        let url = this.normalizeUrl(element.href);
        this.urls.push(url);
        this.links[url] = element;
      });
    }

    this.adjustLinksByURL();
  }

  adjustLinksByURL() {
    // Get the current location's href, normalized
    const currentHref = this.normalizeUrl(window.location.href);
    // Check if the normalized current location's href matches any entry in the urls array
    const isMatch = this.urls.includes(currentHref);
    if (isMatch) {
      this.setActiveStyle(currentHref);
    } else {
      if (this.activeLink != null) this.removeActiveStyle();
    }
  }

  removeActiveStyle() {
    this.styleInactive(this.links[this.activeLink]);
    this.activeLink = null;
  }

  setActiveStyle(url) {
    this.styleActive(this.links[url])
    this.activeLink = url;
  }

  styleActive(ele) {
    ele.classList.add(
      'text-light-nav-text-colour-active',
      'dark:text-dark-nav-text-colour-active',
      '[&_svg]:!stroke-light-nav-text-colour-active',
      'dark:[&_svg]:!stroke-dark-nav-text-colour-active',
      '[&_svg]:!fill-light-nav-text-colour-active',
      'dark:[&_svg]:!fill-dark-nav-text-colour-active'
    );

    if (ele.getAttribute('href') === '/merchant/affiliate') {
      ele.classList.add(
        'bg-light-nav-background-referral-active',
        'dark:bg-dark-nav-background-referral-active',
        '[&>div>div]:bg-none',
        '[&>div>div]:text-white',
      );
    } else {
      ele.classList.add(
        'bg-light-nav-background-active',
        'dark:bg-dark-nav-background-active',
      );
    }
  }

  styleInactive(ele) {
    ele.classList.remove(
      'text-light-nav-text-colour-active',
      'dark:text-dark-nav-text-colour-active',
      '[&_svg]:!stroke-light-nav-text-colour-active',
      'dark:[&_svg]:!stroke-dark-nav-text-colour-active',
      '[&_svg]:!fill-light-nav-text-colour-active',
      'dark:[&_svg]:!fill-dark-nav-text-colour-active'
    );

    if (ele.getAttribute('href') === '/merchant/affiliate') {
      ele.classList.remove(
        'bg-light-nav-background-referral-active',
        'dark:bg-dark-nav-background-referral-active',
        '[&>div>div]:bg-none',
        '[&>div>div]:text-white',
      );
    } else {
      ele.classList.remove(
        'bg-light-nav-background-active',
        'dark:bg-dark-nav-background-active',
      );
    }
  }

  disconnect() {
    document.removeEventListener('turbo:before-visit', this.updateNav)   
  }

  normalizeUrl(url) {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }
}
