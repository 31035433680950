import { Controller } from "@hotwired/stimulus";
import walletService from "../services/WalletService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["buttontext", "button"];

  static values = {
    connectStr: String,
    disconnectStr: String,
  };

  connect() {
    debugLog("Wallet Controller Connected", this);
    if (walletService.isConnected()) {
      if (this.hasButtontextTarget) this.walletTarget.textContent = 'Switch Wallets';
    }
  }

  static values = {};

  initialize() {
    this.walletClient = walletService.getWalletClient();
    this.connectValue = "connect";
    this.disconnectValue = "disconnect";

    this.watchAccounts = async (wallets) => {
      if (walletService.isConnected()) {
        if (this.hasButtontextTarget) {
          this.buttontextTarget.textContent = 'Switch Wallets';
        }
        this.deanimateButton(this.buttonTarget);
      } else {
        if (this.hasButtontextTarget) {
          this.buttontextTarget.textContent = 'Connect Wallet';
        }
        this.animateButton(this.buttonTarget);
      }
    };

    walletService.onWalletChange(this.watchAccounts);
  }

  disconnect() {
    walletService.removeWalletChangeListener(this.watchAccounts);
  }

  deanimateButton(btn) {
    btn.classList.remove("after:bg-button-gradient","after:animate-rotate");
    btn.classList.add("bg-blue-coinsub", "hover:bg-blue-700");
  }

  animateButton(btn) {
    btn.classList.remove("bg-blue-coinsub", "hover:bg-blue-700");
    btn.classList.add("after:bg-button-gradient", "after:animate-rotate");
  }

  enableButton(btn) {
    btn.classList.remove("bg-gray-500", "opacity-50", "cursor-not-allowed");
    btn.classList.add("bg-blue-coinsub", "hover:bg-blue-700");
    btn.disabled = false;
  }

  disableButton(btn) {
    btn.classList.remove("bg-blue-coinsub", "hover:bg-blue-700");
    btn.classList.add("bg-gray-500", "opacity-50", "cursor-not-allowed");
    btn.disabled = true;
  }

  async do() {
    if (this.hasButtontextTarget) {
      switch (this.buttontextTarget.dataset.element) {
        case  this.connectValue:
          await walletService.connectWallet();
          break;
        case  this.disconnectValue:
          await walletService.disconnectWallet();
          await walletService.connectWallet();
          break;
      }
    }
  }
}
