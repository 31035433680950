import { Controller } from "@hotwired/stimulus";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["button", "menu"];
  static values = {};

  initialize() {

    this.buttonClick = (e) => {
        e.stopPropagation();
        this.menuTarget.classList.toggle("hidden");
    
        if (!this.menuTarget.classList.contains("hidden")) {
          window.addEventListener("click", this.handleClickOutside);
        } else {
          window.removeEventListener("click", this.handleClickOutside);
        }
      }

      this.handleClickOutside = (event) => {
        if (!this.buttonTarget.contains(event.target)) {
          this.menuTarget.classList.add("hidden");
          window.removeEventListener("click", this.handleClickOutside);
        }
      }      

  }

  connect() {
    debugLog("Menu controller loaded");
    if (this.hasButtonTarget && this.hasMenuTarget) {
       debugLog("click added");        
      this.buttonTarget.addEventListener("click", this.buttonClick);
    }
  }

  disconnect() {
    this.buttonTarget.removeEventListener("click", this.buttonClick);
  }

}
