import { Controller } from "@hotwired/stimulus";
import httpService from "../services/HttpService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["modal","header","content","button"];
  static values = {
    redirecturl: String, 
    posturl: String,
    open: Boolean,
    dialogId: String,
  };

  connect() {
    debugLog("Modal Controller Connected", this.headerTarget.textContent);
    if (this.hasOpenValue && this.openValue) this.requestOpen();
  }

  close() {
    this.modalTarget.classList.add('hidden');
    if (this.redirecturlValue) {
      Turbo.visit(this.redirecturlValue);
    }
  } 

  async post(e) {
    
    e.preventDefault();
    
    let button = e.target;
    let url = button.dataset.modalPosturlValue;
    this.modalTarget.classList.add('hidden');
    if (url) {
      let res = await httpService.post(
        url,
        {}
      );
      switch (res.type) {
        case "html":
          Turbo.renderStreamMessage(res.data);
          break;
        case "redirect":
          window.location.href = res.data  
          break;
      } 
    }
  } 

  requestOpen() {
    const event = new CustomEvent("open-dialog", {
      detail: {
        dialogId: this.dialogIdValue,
        posturl: this.posturlValue,
      },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  error({ detail: { content } }) {
    this.headerTarget.textContent = "Error";
    this.buttonTarget.textContent = "Close";
    this.contentTarget.innerHTML = content;
    this.requestOpen();
  }

  disconnect() {
    debugLog("Modal Controller Disconnected");    
  }

}
