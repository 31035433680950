import { Controller } from "@hotwired/stimulus";
import { debugLog } from "../utils";
import httpService from "../services/HttpService";
import channelService from "../services/ChannelService";

export default class extends Controller {
  static targets = ['pagination'];

  id = null;
  current = null;
  total = null;
  link = null;

  initialize() {
    const self = this;

    const id = self.paginationTarget.getAttribute('id');
    const current = Number(self.paginationTarget.dataset.current);
    const total = Number(self.paginationTarget.dataset.total);
    const link = self.paginationTarget.dataset.link;

    if (id && current && total && link) {
      self.id = id;
      self.current = current;
      self.total = total;
      self.link = link;

      // self.checkPreviousInstance(id);
      const items = self.generatePaginationItems(self.current, self.total);
      self.generatePaginationElements(items);
    } else {
      throw new Error('Pagination component needs a unique id, link, current and total number of pages!');
    }
  }

  // checkPreviousInstance(id) {
  //   const previousInstance = document.getElementById(id);
  //   if (previousInstance) {
  //     previousInstance.parentElement.removeChild(previousInstance);
  //   }
  // }

  generatePaginationItems(current, total) {
    if (total === 1) {
      return [];
    }

    const items = [];

    function addPaginationItem(item) {
      if (!items.includes(item)) {
        items.push(item);
      }
    }

    const previousThird = current - 3;
    const previousSecond = current - 2;
    const previous = current - 1;
    const next = current + 1;
    const secondNext = current + 2;
    const thirdNext = current + 3;

    addPaginationItem(1);
    if (previousThird > 1) addPaginationItem(previousThird);
    if (previousSecond > 1) addPaginationItem(previousSecond);
    if (previous > 1) addPaginationItem(previous);
    addPaginationItem(current);
    if (next < total) addPaginationItem(next);
    if (secondNext < total) addPaginationItem(secondNext);
    if (thirdNext < total) addPaginationItem(thirdNext);
    addPaginationItem(total);

    return items;
  }

  generatePaginationElements(items) {
    const paginationList = document.createElement('ul');
    let paginationElement;
    let paginationLink;

    for (let i = 0; i < items.length; i++) {
      paginationElement = document.createElement('li');
      if (this.current === items[i]) paginationElement.classList.add('active');
      paginationLink = document.createElement('a');
      paginationLink.setAttribute('href', `${this.link}${items[i]}`);
      paginationLink.textContent = items[i];

      // Add the click event listener here
      paginationLink.addEventListener('click', async (event) => {
        event.preventDefault(); // Prevent default href behavior when element is clicked

        const url = event.target.href; // Get the href attribute
        try {
          const res = await httpService.get(url);
          console.log("Response:", res);
          channelService.connect();
          await Turbo.renderStreamMessage(res.data);

          // Update pagination
          const newCurrent = parseInt(event.target.textContent, 10);
          this.current = newCurrent;
          const newItems = this.generatePaginationItems(this.current, this.total);
          this.paginationTarget.innerHTML = ''; // Clear existing pagination
          this.generatePaginationElements(newItems); // Generate new pagination elements
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      });

      paginationElement.appendChild(paginationLink);
      paginationList.appendChild(paginationElement);
    }

    this.paginationTarget.appendChild(paginationList);
  }
}