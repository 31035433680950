import Pusher from "pusher-js";
import { debugLog } from "../utils";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = process.env.PARCEL_DEBUG_MODE === "true";

class ChannelService {
  constructor() {
    if (!ChannelService.instance) {
      this.channels = {};
      this.pusher = new Pusher(process.env.PARCEL_PUSHER_KEY, {
        cluster: "us2",
      });
      ChannelService.instance = this;
      this.connect();
    }
  }

  connect() {
    let channel = this.getCookie("channel");
    debugLog("Connect Called: ", channel);
    if (channel) {
      this.subscribeToChannel("stream", channel);
    }
  }

  getCookie(name) {
    let cookieArray = document.cookie.split(";"); // Split the cookie string into individual name=value pairs

    for (let cookie of cookieArray) {
      let [cookieName, cookieValue] = cookie.split("="); // Split each pair into name and value

      cookieName = cookieName.trim(); // Trim whitespace from the cookie name

      if (cookieName === name) {
        return decodeURIComponent(cookieValue); // Return the value of the cookie
      }
    }
    return null; // Return null if the cookie is not found
  }

  subscribeToChannel(type, identifier) {
    const channelName = identifier;
    if (this.channels[channelName]) {
      return; // Already subscribed
    }
    this.unsubscribeFromChannel(type); // Unsubscribe from previous channel of the same type

    // Subscribe to new channel
    let channel = this.pusher.subscribe(channelName);
    channel.bind("html", async function (data) {
      Turbo.renderStreamMessage(data);
    });
    this.channels[type] = { name: channelName, channel: channel };
  }

  unsubscribeFromChannel(type) {
    const channelInfo = this.channels[type];
    if (channelInfo && channelInfo.channel) {
      channelInfo.channel.unbind_all();
      channelInfo.channel.unsubscribe();
      delete this.channels[type];
    }
  }
}

const instance = new ChannelService();
export default instance;
