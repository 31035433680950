import { Controller } from "@hotwired/stimulus";
import { debugLog } from "../utils";

const tooltip = 'tooltip';
const tooltipContent = {
  'stable-coin': '<div class="tooltip-body">Supported Networks:<br>- Polygon<br>- Ethereum<br>Supported Tokens:<br>USDC, USDT, PYUSD</div>',
}

/*
  Bug: When user clicks on 'back' button to return to a page
    with tooltips, his last tooltip will remain active.
    It will not go away until user hovers to an
    element that have another tooltip controller. This behaviour
    does not reflect earlier tooltips.
*/

export default class extends Controller {
  static targets = ['tooltip'];

  connect() {
    this.tooltipTarget.addEventListener('mouseover', this.createTooltip.bind(this));
    this.tooltipTarget.addEventListener('mouseout', this.removeTooltips);
  }

  createTooltip() {
    const tooltipBody = document.createElement('div');
    const alreadyExists = Boolean(tooltipBody.getElementsByClassName('tooltip-body')[0]);

    if (!alreadyExists) {
      tooltipBody.classList.add(tooltip);
      tooltipBody.innerHTML = tooltipContent[this.tooltipTarget.dataset.content];
      this.tooltipTarget.appendChild(tooltipBody);
    }
  }

  removeTooltips() {
    const tooltips = document.getElementsByClassName(tooltip);

    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].parentElement.removeChild(tooltips[i]);
    }
  }
}
