import { Controller } from "@hotwired/stimulus";
import walletService from "../services/WalletService";
import httpService from "../services/HttpService";
import { debugError, debugLog } from "../utils";
import { hexToSignature } from "viem";

// Import ChannelService to initialize it and start listening for wallet changes.
// The service is a singleton and starts functioning upon import.
import channelService from "../services/ChannelService";

export default class extends Controller {
  static targets = ["button", "coins"];

  static values = {
    merchant: String,
    subscription: String,
    subscriptionproductid: Number,
    token: String,
    to: String,
    amount: Number,
    numberofpayments: String,
    durationindays: Number,
    nonce: Number,
    step: String,
    tokensymbol: String,
    tokencontract: String,
    dsv: Number,
    verifyingcontract: String,
    tokenversion: String,
    tokencontractaddress: String,
    tokenname: String,
    subscriptionid: Number,
    spenderaddress: String,
    percent: Number,
    text: String,
    networkid: String,
    tokenid: String,
    chainid: Number,
    walletAddress: String
  };

  initialize() {
    debugLog("Initializing Subscribe Controller");

    this.watchWallets = async (wallets) => {

      debugLog("Subscribe Controller: Watch Wallets Called.");

      let oldAddress = this.walletAddressValue;
      let address = walletService.getWalletAddress();
      this.walletAddressValue = address;
  
      if (walletService.isConnected()) {
  
        if (oldAddress != address) {
  
          debugLog("WC OLD Address: ", oldAddress);
          debugLog("WC NEW Address: ", address);
  
          try {
  
            const walletIntel = await httpService.get(
              `/buy/presign/${address}/${this.subscriptionproductidValue}`
            );
  
            debugLog('res.message', walletIntel.message)
  
            if (walletIntel.type == "html") {
  
              debugLog('Sending HTML to Turbo Steam', walletIntel.type)
              await Turbo.renderStreamMessage(walletIntel.data);
  
            }
  
          } catch (error) {
            debugLog("Error fetching presign endpoint:", error);
          }
  
        }
  
      } else {
        this.disableButton(this.buttonTarget);
      }
    }

  }

  disconnect() {
    walletService.removeWalletChangeListener(this.watchWallets);
  }

  async connect() {
    
    if (this.hasPercentValue) {
      this.updateProgress();
    }

    this.walletAddressValue = walletService.getWalletAddress();
    walletService.onWalletChange(this.watchWallets);
    if (walletService.isConnected()) {
        if (this.hasChainidValue && Number.isInteger(this.chainidValue) && this.chainidValue > 0) await walletService.changeChains(this.chainidValue)
    }

  }

  disableButton(btn) {
    btn.classList.remove("bg-blue-coinsub", "hover:bg-blue-700");
    btn.classList.add("bg-gray-500", "opacity-50", "cursor-not-allowed");
    btn.disabled = true;
  }


  updateProgress() {
    //update circle
    let circle = document.getElementById("progressCircle");
    if (circle) {
      const radius = circle.r.baseVal.value;
      const circumference = 2 * Math.PI * radius;

      debugLog("This.percentValue: ", this.percentValue);

      if (this.percentValue == 0) {
        circle.style.strokeDashoffset = circumference;
      } else {
        const offset =
          circumference - (this.percentValue / 100) * circumference;
        circle.style.strokeDashoffset = offset;
      }

      debugLog("Progress updated");
    }

    //update message
    let textDiv = document.getElementById("progressText");
    if (textDiv) {
      textDiv.textContent = this.textValue;
    }

    if (this.percentValue == 100 || this.percentValue == 0) {
      debugLog("This.percentValue2: ", this.percentValue);
      //disable the working bar
      let progresBlock = document.getElementById("progress");
      if (progresBlock) {
        if (this.percentValue == 0) circle.classList.add("hidden");
        progresBlock.classList.add("hidden");
      }
    }
  }

  showWalletConnectMessageAndDisableButton() {
    let messageDiv = document.getElementById('walletConnectMessage');
    let button = this.buttonTarget; // Using Stimulus to directly reference the button

    // Display the message
    if (messageDiv) {
      messageDiv.style.visibility = 'visible';
    }

    // Use your existing disableButton function
    this.disableButton(button);

    // Set a timeout to hide the message and re-enable the button after 5 seconds
    setTimeout(() => {
      if (messageDiv) {
          messageDiv.style.visibility = 'hidden';
      }

      // Directly toggle the button's disabled state and classes to re-enable it
      button.disabled = false;
      button.classList.remove("bg-gray-500", "opacity-50", "cursor-not-allowed");
      button.classList.add("bg-blue-coinsub", "hover:bg-blue-700");
    }, 8000);
  }

  showPermitlessGasNotificationAndDisableButton() {
    let messageDiv = document.getElementById('permitlessGasNotification');
    let button = this.buttonTarget; // Using Stimulus to directly reference the button

    // Display the message
    if (messageDiv) {
      messageDiv.style.visibility = 'visible';
    }

    // Use your existing disableButton function
    this.disableButton(button);

    // Set a timeout to hide the message and re-enable the button after 5 seconds
    setTimeout(() => {
      if (messageDiv) {
          messageDiv.style.visibility = 'hidden';
      }

      // Directly toggle the button's disabled state and classes to re-enable it
      button.disabled = false;
      button.classList.remove("bg-gray-500", "opacity-50", "cursor-not-allowed");
      button.classList.add("bg-blue-coinsub", "hover:bg-blue-700");
    }, 5000);
  }

  async processInput() {
    let address = walletService.getWalletAddress();

    let data, walletClient;

    switch (this.stepValue) {
      case "subscribe":
        walletClient = walletService.getWalletClient();
        if (walletClient) {
          data = this.buildSubscriptionData(
            this.merchantValue,
            this.subscriptionValue,
            this.subscriptionproductidValue,
            this.tokenValue,
            this.toValue,
            this.amountValue,
            this.numberofpaymentsValue,
            this.durationindaysValue,
            this.nonceValue,
            this.verifyingcontractValue
          );

          this.showWalletConnectMessageAndDisableButton();

          debugLog("Subscription:", data);

          const signature = await walletClient.signTypedData({
            domain: data.domain,
            message: data.message,
            primaryType: data.primaryType,
            types: data.types,
          });

          data.rawSignature = signature;
          data.signature = hexToSignature(signature);

          // Convert v and yParity to strings
          data.signature.v = data.signature.v.toString();
          data.signature.yParity = data.signature.yParity.toString();

          data.address = walletService.getWalletAddress();
          debugLog("Subscription:", data);

          // Post the data to the processing endpoint
          try {
            let res = await httpService.post(`/buy/sign/`, data);
            channelService.connect();
            switch (res.type) {
              case "html":
                Turbo.renderStreamMessage(res.data);
                break;
              case "redirect":
                Turbo.visit(res.data)
                break;
            }
          } catch (error) {
            this.dispatch("error", { detail: { content: error.message } });
          }
        } else {
          await walletService.connectWallet();
        }

        break;
      case "subscriber":
        let form = document.getElementById("subscriberForm");
        let formData = new FormData(form);

        let formObject = {};
        formData.forEach((value, key) => {
          // Handle nested structure for customFieldData
          if (key.startsWith("custom.")) {
              let customKey = key.split(".")[1];
              if (!formObject.customFieldData) {
                  formObject.customFieldData = {};
              }
              formObject.customFieldData[customKey] = value;
          } else {
              // Regular fields
              formObject[key] = value;
          }
      });
        debugLog("Form Data: ", formObject);

        try {
          let res = await httpService.post(
            `/buy/subscriber/`,
            formObject
          );
          debugLog("Response:", res);
        } catch (error) {
          this.dispatch("error", { detail: { content: error.message } });
        }

        break;
      case "locallyapprove":
        debugLog("locallyapprove called")
        let chain = walletService.getChain(this.chainidValue);
        walletClient = walletService.getWalletClient();
        debugLog("isConnected(): ", walletService.isConnected())
        debugLog("getWalletAddress(): ", walletService.getWalletAddress())
        debugLog("getWalletClient(): ", walletClient)
        debugLog("getNetworkId(): ", walletService.getNetworkId())        
        debugLog("getChain(): ", chain)         

        this.showPermitlessGasNotificationAndDisableButton()

        try {
          let contractAddress = this.tokencontractaddressValue;
          let walletAddress = address;
          const ABI = [{
            "constant": false,
            "inputs": [
                {
                    "name": "_spender",
                    "type": "address"
                },
                {
                    "name": "_value",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [
                {
                    "name": "",
                    "type": "bool"
                }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        }];
        debugLog('wallet address and contract address assigned')
        debugLog("contractAddress:", contractAddress)
        debugLog("walletAddress:", walletAddress)
        debugLog("this.spenderaddressValue:", this.spenderaddressValue)
        debugLog("this.process.env.PARCEL_MAX_AMOUNT:", process.env.PARCEL_MAX_AMOUNT)
        
        //const { request } = await walletClient.simulateContract()  
        let txhash = await walletClient.writeContract({
          account: walletAddress,
          address: contractAddress,
          abi: ABI,
          functionName: 'approve',
          args: [this.spenderaddressValue, process.env.PARCEL_MAX_AMOUNT],
          chain: chain
        })

         debugLog("txhash:", txhash)
          let data = {
            txhash: txhash,
            subscriptionId: this.subscriptionidValue
          }
          debugLog("data assigned:", data)
          let postres = await httpService.post(`/buy/permitless/`, data);
        } catch (error) {
          debugError("FAILED TO APPROVE LOCALLY: ", error);
          this.dispatch("error", { detail: { content: error.message } });
        }

        break;
      case "permit":
        let signedData = {};
        signedData.nonce = this.nonceValue;
        signedData.deadline = (Math.floor(Date.now() / 1000) + 1800).toString();
        signedData.amount = process.env.PARCEL_MAX_AMOUNT;
        signedData.address = walletService.getWalletAddress();

        data = this.buildPermitData(
          signedData.address,
          signedData.amount,
          signedData.nonce,
          signedData.deadline
        );

        walletClient = walletService.getWalletClient();
        if (walletClient) {
          const permitSignature = await walletClient.signTypedData({
            domain: data.domain,
            message: data.message,
            primaryType: data.primaryType,
            types: data.types,
          });

          data.subscriptionId = this.subscriptionidValue;
          data.rawSignature = permitSignature;
          data.signature = hexToSignature(permitSignature);

          // Convert v and yParity to strings
          data.signature.v = data.signature.v.toString();
          data.signature.yParity = data.signature.yParity.toString();

          data.address = walletService.getWalletAddress();

          // Post the data to the processing endpoint
          debugLog("Permit:", data);
          try {
            let res = await httpService.post(`/buy/permit/`, data);
            debugLog("Response:", res);
          } catch (error) {
            this.dispatch("error", { detail: { content: error.message } });
          }
        }
        break;
    }
  }

  buildPermitData(owner, amount, nonce, deadline) {
    //EIP712 Domain Data
    const name = this.tokennameValue;
    const version = this.tokenversionValue;
    const chainId = parseInt(this.chainidValue, 10);
    const verifyingContract = this.tokencontractaddressValue;
    const spenderContract = this.spenderaddressValue;

    //Permit Domain
    const permitDomain = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];

    let domain;
    if (this.dsvValue == "1") {
      const salt = "0x" + chainId.toString(16).padStart(64, "0");
      domain = {
        name: name,
        version: version,
        verifyingContract: verifyingContract,
        salt: salt,
      };
    } else {
      domain = {
        name: name,
        version: version,
        chainId: chainId,
        verifyingContract: verifyingContract,
      };
    }

    return {
      primaryType: "Permit",
      types: { Permit: permitDomain },
      domain: domain,
      message: {
        owner: owner,
        spender: spenderContract,
        value: amount,
        nonce: nonce,
        deadline: deadline,
      },
    };
  }

  buildSubscriptionData(
    companyName,
    subTitle,
    subscriptionId,
    token,
    to,
    amount,
    numberOfPayments,
    durationInDays,
    nonce,
    verifyingContract
  ) {
    //EIP712 Domain Data
    const name = "CoinSubAgent";
    const version = "1";
    const chainId = parseInt(this.chainidValue, 10);
    debugLog("VerifyingContract", verifyingContract);

    //string M,string S,uint256 I,string T,address to,uint256 A,string P,uint256 D,uint256 nonce
    //Subscribe Domain
    const subscribeDomain = [
      { name: "M", type: "string" },
      { name: "S", type: "string" },
      { name: "I", type: "uint256" },
      { name: "T", type: "string" },
      { name: "to", type: "address" },
      { name: "A", type: "uint256" },
      { name: "P", type: "string" },
      { name: "D", type: "uint256" },
      { name: "nonce", type: "uint256" },
    ];

    return {
      primaryType: "Subscribe",
      types: { Subscribe: subscribeDomain },
      domain: {
        name: name,
        version: version,
        chainId: chainId,
        verifyingContract: verifyingContract,
      },
      message: {
        M: companyName,
        S: subTitle,
        I: subscriptionId,
        T: token,
        to: to,
        A: amount,
        P: numberOfPayments,
        D: durationInDays,
        nonce: nonce,
      },
    };
  }

}
