import { Controller } from "@hotwired/stimulus";
import channelService from "../services/ChannelService";
import { debugLog } from "../utils";

export default class extends Controller {

/* when the channel is shifted on the backend, 
  the socket controller is loaded on the front-end
   to reconnect. */
      
  connect() {
    debugLog("Socket Controller Loaded");
    channelService.connect();
  }

}
