import "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";
import ButtonController from "./controllers/ButtonController";
import SubscribeController from "./controllers/SubscribeController";
import LoginController from "./controllers/LoginController";
import ModalController from "./controllers/ModalController";
import ModalContainerController from "./controllers/ModalContainerController";
import MenuController from "./controllers/MenuController";
import DropMenuController from "./controllers/DropMenuController";
import FormController from "./controllers/FormController";
import ProductFormController from "./controllers/ProductFormController";
import SocketController from "./controllers/SocketController";
import ColorisController from "./controllers/ColorisController";
import NavigationController from "./controllers/NavigationController";
import WalletController from "./controllers/WalletController";
import ChartController from "./controllers/ChartController";
import IntervalController from "./controllers/IntervalController";
import PaginationController from "./controllers/PaginationController";
import PaymentMethodController from "./controllers/PaymentMethodController";
import TooltipController from "./controllers/TooltipController";
import ThemeController from "./controllers/ThemeController";
import ReferralTermsController from "./controllers/ReferralTermsController";
import { debugError, debugLog } from "./utils";

window.Stimulus = Application.start();
window.Stimulus.state = {};

Stimulus.debug = process.env.PARCEL_DEBUG_MODE;
Stimulus.register("button", ButtonController);
Stimulus.register("subscribe", SubscribeController);
Stimulus.register("modal", ModalController);
Stimulus.register("modal-container", ModalContainerController);
Stimulus.register("login", LoginController);
Stimulus.register("menu", MenuController);
Stimulus.register("dropmenu", DropMenuController);
Stimulus.register("form", FormController);
Stimulus.register("productform", ProductFormController);
Stimulus.register("socket", SocketController);
Stimulus.register("coloris", ColorisController);
Stimulus.register("nav", NavigationController);
Stimulus.register("wallet", WalletController);
Stimulus.register("chart", ChartController);
Stimulus.register("interval", IntervalController);
Stimulus.register("pagination", PaginationController);
Stimulus.register("paymentmethod", PaymentMethodController);
Stimulus.register("tooltip", TooltipController);
Stimulus.register("theme", ThemeController);
Stimulus.register("referralterms", ReferralTermsController);
Stimulus.handleError = (error, message, detail) => {
  debugError(message, detail, error)
};

debugLog('App Started.');
