import { Controller } from '@hotwired/stimulus';
import httpService from "../services/HttpService";
import { debugLog } from '../utils';

export default class extends Controller {
  connect() {
    const cookies = document.cookie;

    if (cookies.search('theme=dark') !== -1) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  async toggleTheme() {
    const darkThemeIsActive = document.documentElement.classList.contains('dark');
    var theme = darkThemeIsActive ? "light" : "dark";
    
    // Send mode to backend - to fix flicker issues
    await httpService.get(`/theme/${theme}`);
  
    if (darkThemeIsActive) {
      document.documentElement.classList.remove('dark');
      document.cookie = `theme=light; path=/`;
    } else {
      document.documentElement.classList.add('dark');
      document.cookie = `theme=dark; path=/`;
    }
  }
  
}
