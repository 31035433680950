import { Controller } from "@hotwired/stimulus";
import walletService from "../services/WalletService";
import { debugLog } from "../utils";

const buttonStyles = {
  // Always remove animation first, before applying disabled state.
  default: ["bg-blue-coinsub", "dark:bg-gold-light", "[&>div]:hover:bg-blue-700"],
  animation: ["after:bg-light-button-animated", "dark:after:bg-dark-button-animated", "after:animate-rotate", "[&>div]:hover:bg-white/20"],
  disabled: ["bg-gray-500", "dark:bg-gold-subtle-brown", "dark:text-gold-sand", "cursor-not-allowed"],
};

export default class extends Controller {
  static targets = ["wallet", "button", "signin", "address", "sig", "form", "iat"];

  static values = {
    connectStr: String,
    disconnectStr: String,
  };

  connect() {
    debugLog("Login Controller Connected", walletService.isConnected());
    this.handleButtonStates();
  }

  static values = {};

  initialize() {
    this.walletClient = walletService.getWalletClient();
    this.connectValue = "connect";
    this.disconnectValue = "disconnect";

    this.watchAccounts = async (wallets) => {
      debugLog("Wallets changes:", wallets);
      this.handleButtonStates();
    };

    walletService.onWalletChange(this.watchAccounts);
  }

  handleButtonStates() {
    if (walletService.isConnected()) {
      debugLog("Wallet connected.");

      if (this.hasWalletTarget) {
        this.walletTarget.textContent = 'Disconnect Wallet';
        this.walletTarget.dataset.element = this.disconnectValue;
        this.deanimateButton(this.buttonTarget);
      }

      if (this.hasSigninTarget) {
        this.enableButton(this.signinTarget);
        this.animateButton(this.signinTarget);
      }
    } else {
      debugLog("Need to connect wallet.");

      if (this.hasWalletTarget) {
        this.walletTarget.textContent = 'Connect Wallet';
        this.walletTarget.dataset.element = this.connectValue;
        this.animateButton(this.buttonTarget);
      }

      if (this.hasSigninTarget) {
        this.deanimateButton(this.signinTarget);
        this.disableButton(this.signinTarget);
      }
    }
  }

  disconnect() {
    debugLog('Dsconnection triggered!');
    walletService.removeWalletChangeListener(this.watchAccounts);
  }

  deanimateButton(btn) {
    btn.classList.remove(...buttonStyles.animation);
    btn.classList.add(...buttonStyles.default);
  }

  animateButton(btn) {
    btn.classList.remove(...buttonStyles.default);
    btn.classList.add(...buttonStyles.animation);
  }

  enableButton(btn) {
    btn.disabled = false;
    btn.classList.remove(...buttonStyles.disabled);
    btn.classList.add(...buttonStyles.default);
  }

  disableButton(btn) {
    btn.disabled = true;
    btn.classList.remove(...buttonStyles.default);
    btn.classList.add(...buttonStyles.disabled);
  }

  siwe() {
    const data = {
      domain: window.location.host,
      from: walletService.getWalletAddress(),
      nonce: getCookie("channel"),
      chainId: process.env.PARCEL_CHAIN_ID,
      iat: new Date().toISOString()
    }

    data.message = `${data.domain} wants you to sign in with your Ethereum account:\n${data.from}\n\nI accept the Coinsub Terms of Service: https://coinsub.io/tos\n\nURI: https://${data.domain}\nVersion: 1\nChain ID: ${data.chainId}\nNonce: ${data.nonce}\nIssued At: ${data.iat}`;
    debugLog(data.message);
    delete data.nonce;

    return data;
  }

  disableSignInButtonFor5Seconds() {
    const signInButton = document.getElementById('signInButton');
    const signInMessage = document.getElementById('signInMessage');

    signInButton.classList.remove(...buttonStyles.animation);
    signInButton.classList.add(...buttonStyles.disabled);
    signInMessage.classList.remove('invisible');

    setTimeout(function () {
      signInButton.classList.remove(...buttonStyles.disabled);
      signInButton.classList.add(...buttonStyles.animation);
      signInMessage.classList.add('invisible');
    }, 5000);
  }

  async signin() {
    this.disableSignInButtonFor5Seconds();

    if (walletService.isConnected()) {
      //let message = "Sign in to Coinsub: " + getCookie("channel");
      let siweData = this.siwe();
      let walletClient = walletService.getWalletClient();

      try {
        let signature = await walletClient.signMessage({ account: walletService.getWalletAddress(), message: siweData.message });

        // Check if a signature was obtained
        if (signature) {
          // Process the successful signature case
          let address = walletService.getWalletAddress();

          if (this.hasAddressTarget) {
            this.addressTarget.value = address;
          }
          if (this.hasSigTarget) {
            this.sigTarget.value = signature;
          }

          if (this.hasIatTarget) {
            this.iatTarget.value = siweData.iat;
          }

          if (this.hasFormTarget) {
            this.formTarget.submit();
          }

          debugLog("Signed message:", signature, siweData.message);
        } else {
          // Handle the case where the user cancelled the signing or no signature was returned
          debugLog("Signing process cancelled or failed to obtain signature.");
        }
      } catch (error) {
        // Handle any errors that occur during the signing process
        debugLog("Error during signing process:", error);
      }
    } else {
      this.wallet();
    }
  }

  async wallet() {
    if (this.hasWalletTarget) {
      switch (this.walletTarget.dataset.element) {
        case this.connectValue:
          await walletService.connectWallet();
          break;
        case this.disconnectValue:
          await walletService.disconnectWallet();
          break;
      }
    }
  }
}

function getCookie(name) {
  let cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split("=");
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}
