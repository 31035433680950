import { Controller } from "@hotwired/stimulus";
import { debugLog } from "../utils";

const selectorID = 'interval_selector';
const passiveIntervalSelectorString = 'Select Period';

export default class extends Controller {
  static targets = ['container'];

  static values = {};

  initialize() {
    const self = this;

    const previousSelector = document.getElementById(selectorID);

    if (previousSelector) {
      previousSelector.parentElement.removeChild(previousSelector);
    };

    self.intervals = self.containerTarget.dataset.items.split(', ');

    const selectElement = document.createElement("SELECT");
    selectElement.setAttribute('id', selectorID)
    const blankOptionElement = document.createElement('OPTION');
    blankOptionElement.textContent = passiveIntervalSelectorString;
    selectElement.appendChild(blankOptionElement);

    for (let i = 0; i < self.intervals.length; i++) {
      const optionProperties = self.intervals[i].split('_');
      const optionElement = document.createElement('OPTION');
      optionElement.textContent = optionProperties[0];
      optionElement.value = optionProperties[1];
      selectElement.appendChild(optionElement);
    }

    self.containerTarget.addEventListener('change', function() {
      const value = document.getElementById(selectorID).value;

      if (value === passiveIntervalSelectorString) {
        return;
      } else {
        window.location.href = window.location.origin + value;
      }
    });

    self.containerTarget.appendChild(selectElement);
  }
}
