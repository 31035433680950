import EventEmitter from "events";
import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseModule from "@web3-onboard/coinbase";
import safeModule from '@web3-onboard/gnosis'
//import metamaskSDK from "@web3-onboard/metamask";
import { createWalletClient, custom, hexToSignature } from "viem";
import { polygon, polygonAmoy, sepolia, mainnet, baseSepolia, base  } from "viem/chains";
import { debugLog, debugError } from "../utils";

// Initialize the wallet modules
/*
const metamaskSDKWallet = metamaskSDK({
  options: {
      extensionOnly: false,
      dappMetadata: {
          name: "Coinsub",
          url: `${process.env.PARCEL_WC_DAPP_URL}`
      },
  },
});
*/
const injected = injectedModule();
const safe = safeModule();

const walletConnect = walletConnectModule({
  projectId: `${process.env.PARCEL_WC_PROJECT_ID}`,
  dappUrl: `${process.env.PARCEL_WC_DAPP_URL}`
});

const coinbaseWallet = coinbaseModule();

const customTheme = {
  "--w3o-background-color": "#ffffff",
  "--w3o-foreground-color": "#edf3f8",
  "--w3o-text-color": "#262626",
  "--w3o-border-color": "#c7c7c7",
  "--w3o-action-color": "#0085ff",
  "--w3o-border-radius": "unset",
  "--w3o-font-family": "unset",
};

const supportedWallets = [safe, coinbaseWallet, walletConnect, injected]; // metamaskSDKWallet

const chains = [
  {
    id: polygonAmoy.id,
    token: polygonAmoy.nativeCurrency.name,
    label: polygonAmoy.name,
    rpcUrl: (polygonAmoy.rpcUrls.public) ? polygonAmoy.rpcUrls.public.http[0] : polygonAmoy.rpcUrls.default.http[0],
  },
  {
    id: polygon.id,
    token: polygon.nativeCurrency.name,
    label: polygon.name,
    rpcUrl: (polygon.rpcUrls.public) ? polygon.rpcUrls.public.http[0] : polygon.rpcUrls.default.http[0],
  },
  {
    id: sepolia.id,
    token: sepolia.nativeCurrency.name,
    label: sepolia.name,
    rpcUrl: (sepolia.rpcUrls.public) ? sepolia.rpcUrls.public.http[0] : sepolia.rpcUrls.default.http[0],
  },  
  {
    id: mainnet.id,
    token: mainnet.nativeCurrency.name,
    label: mainnet.name,
    rpcUrl: (mainnet.rpcUrls.public) ? mainnet.rpcUrls.public.http[0] : mainnet.rpcUrls.default.http[0],
  },  
  {
    id: baseSepolia.id,
    token: baseSepolia.nativeCurrency.name,
    label: baseSepolia.name,
    rpcUrl: (baseSepolia.rpcUrls.public) ? baseSepolia.rpcUrls.public.http[0] : baseSepolia.rpcUrls.default.http[0],
  },  
  {
    id: base.id,
    token: base.nativeCurrency.name,
    label: base.name,
    rpcUrl: (base.rpcUrls.public) ? base.rpcUrls.public.http[0] : base.rpcUrls.default.http[0],
  }    
];

const appMetadata = {
  name: "Coinsub",
  icon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
    <svg
      width="272.78668"
      height="190.96716"
      viewBox="0 0 272.78668 190.96716"
      fill="none"
      version="1.1"
      id="svg12"
      sodipodi:docname="logo-mobile.svg"
      inkscape:version="1.3 (0e150ed6c4, 2023-07-21)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <sodipodi:namedview
        id="namedview12"
        pagecolor="#ffffff"
        bordercolor="#000000"
        borderopacity="0.25"
        inkscape:showpageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1"
        inkscape:zoom="0.81291946"
        inkscape:cx="352.43344"
        inkscape:cy="89.184727"
        inkscape:window-width="1858"
        inkscape:window-height="1057"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="svg12" />
      <path
        d="m 11.301914,20.82734 c -9.18999,20.5916 -17.93698,35.7161 -4.0808,56.7847 26.1516,39.7637 101.131496,105.4767 199.978496,113.1537 29.348,2.279 40.818,-15.131 60.672,-34.522 -63.513,58.766 -277.619386,-63.491 -256.569696,-135.4164 z"
        fill="url(#paint0_linear_15_59)"
        id="path9"
        style="fill:url(#paint0_linear_15_59)" />
      <path
        d="m 247.16361,163.12974 c -5.893,-32.692 -85.92,-73.533 -85.92,-73.533 -0.325,0.973 -0.66,1.941 -0.994,2.907 -1.753,5.069 -3.491,10.091 -3.675,15.533 C 65.816514,61.69844 60.760914,19.28034 58.732914,2.2645405 c -0.094,-0.7882 -0.1814,-1.5219 -0.2706,-2.1989 -61.7634323,-2.0471 -46.047,44.3016995 -25.7864,67.0470995 35.7344,50.533 149.905696,117.945 214.487696,96.017 z"
        fill="#0085ff"
        id="path10" />
      <path
        d="M 73.942514,0.8740405 C 75.118614,38.54484 131.85861,67.21654 131.85861,67.21654 c 0.933,-2.919 1.7,-6.0035 2.467,-9.0886 0.776,-3.1234 1.553,-6.2476 2.502,-9.2014 80.03,41.3402 120.701,77.1362 122.619,108.8932 55.679,-30.346 -73.945,-141.0908 -185.504096,-156.9456995 z"
        fill="#0085ff"
        id="path11" />
      <defs
        id="defs12">
        <linearGradient
          id="paint0_linear_15_59"
          x1="212.405"
          y1="197.17799"
          x2="33.5578"
          y2="93.317299"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-3.213386,-16.25626)">
          <stop
            stop-color="#595959"
            id="stop11" />
          <stop
            offset="1"
            stop-color="#242424"
            id="stop12" />
        </linearGradient>
      </defs>
    </svg>`,
  description: "Improving Subscriptions",
  recommendedInjectedWallets: [
    { name: "MetaMask", url: "https://metamask.io" },
  ],
};

class WalletService extends EventEmitter {
  constructor() {
    super();

    if (!WalletService.instance) {
      this.initializeWallets();
      WalletService.instance = this;
    }

    return WalletService.instance;
  }
  initializeWallets() {
    // Configure and start Onboard
    this.onboard = Onboard({
      apiKey: `${process.env.PARCEL_ONBOARD_API_KEY}`,
      wallets: supportedWallets,
      chains,
      appMetadata,
      theme: customTheme,
      connect: {
        autoConnectLastWallet: false,
      },
      accountCenter: {
        desktop: {
          enabled: false,
        },
        mobile: {
          enabled: false,
        },
      },
    });

    this.chainId = 0;
    this.currentChain = null;
    this.walletClient = null;

    // Subscribe to wallet state changes
    this.observableWallets = this.onboard.state.select("wallets");
    this.observableWallets.subscribe((wallets) => {
      this.handleWalletsChange(wallets);
      this.emit("walletChange", wallets);
    });

  }

  // Method to check if the wallet is connected
  isConnected() {
    const state = this.onboard.state.get();
    return state.wallets.length > 0;
  }

  async changeChains(chainId) {

    debugLog("changeChains(chainId): ", chainId)
    
    if (this.chainId != chainId) {
      this.chainId = chainId;
    }
    if (this.getNetworkId() != this.chainId || this.walletClient == null) {
      debugLog("changing to chain: ", chainId)

      this.currentChain = chains.find(chain => chain.id === this.chainId);
      await this.onboard.setChain({ chainId: this.chainId });

      debugLog("this.currentChain: ", this.currentChain, "this.chainId: ", this.chainId, "this.walletClient: ", this.walletClient)

      this.connectWalletClient();
    } 

  }

  getChain(chainId) {
    return chains.find(chain => chain.id === chainId);
  }

  getCurrentChain() {
    return this.currentChain
  }

  // Method to get the address of the connected wallet
  getWalletAddress() {
    const state = this.onboard.state.get();
    if (state.wallets.length > 0) {
      return state.wallets[0].accounts[0].address;
    }
    return null;
  }

  // Method to get the network ID of the connected wallet
  getNetworkId() {
    const state = this.onboard.state.get();
    if (state.wallets.length > 0) {
      return state.wallets[0].chains[0].id;
    }
    return null;
  }

  // Method to be called by other components to handle wallet changes
  onWalletChange(callback) {
    this.on("walletChange", callback);
  }

  // Method to remove the event listener
  removeWalletChangeListener(callback) {
    this.removeListener("walletChange", callback);
  }

  async connectWallet() {
    this.wallets = await this.onboard.connectWallet();
    this.changeChains(this.getNetworkId())
  }

  async disconnectWallet() {
    const [primaryWallet] = this.onboard.state.get().wallets
    await this.onboard.disconnectWallet({ label: primaryWallet.label });
  }

  async handleWalletsChange(wallets) {
    if (wallets.length > 0) {
        this.connectWalletClient();
    } else {
      this.walletClient = null;
    }
  }
  
  connectWalletClient() {

    if (this.wallets && this.wallets[0] && this.wallets[0].accounts[0]) {
      this.walletClient = createWalletClient({
        account: this.wallets[0].accounts[0].address,
        chain: this.currentChain,
        transport: custom(this.wallets[0].provider),
      });
    } 

  }

  getWalletClient() {
    if (!this.walletClient) {
      debugError("Wallet client is not available!", this.walletClient);
      return null; // Or throw an error, depending on your error handling strategy
    }
    return this.walletClient;
  }

}

const instance = new WalletService();
export default instance;
