import { Controller } from "@hotwired/stimulus";
import httpService from "../services/HttpService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["button", "form"];

  static values = {
    posturl: String     
  };

  initialize() {
    this.buttonClick = async (e) => {
      e.stopPropagation();
      let formData = new FormData(this.formTarget);

      let formObject = {};
      formData.forEach((value, key) => {
        // Handle nested structure for customFieldData
        if (key.startsWith("custom.")) {
          let customKey = key.split(".")[1];
          if (!formObject.customFieldData) {
            formObject.customFieldData = {};
          }
          formObject.customFieldData[customKey] = value;
        } else {
          // Regular fields
          formObject[key] = value;
        }
      });

      debugLog("Form Data: ", formObject);
      debugLog("Post URL: ", this.posturlValue);

      try {
        let res = await httpService.post(
          this.posturlValue,
          formObject
        );

        switch (res.type) {
          case "html":
            Turbo.renderStreamMessage(res.data);
            break;
          case "redirect":
            Turbo.visit(res.data)  
            break;
        }  
      } catch (error) {
        this.dispatch("error", { detail: { content: error.message } });
      }
    }
  }

  togglePaymentMode(e) {
    e.preventDefault();
    let id = e.srcElement.id;
    let field = document.getElementById('number_of_payments');
    let rButton = document.getElementById('recurring_button');
    let sButton = document.getElementById('single_payment_button');
    let billing = document.getElementById('billing_period_section');     

    switch (id) {
      case 'recurring_button':
        field.value = 'Until Cancelled';
        sButton.classList.remove("!border-[#003363]", "dark:!border-[#467af5]")
        rButton.classList.add("!border-[#003363]", "dark:!border-[#467af5]")               
        billing.classList.remove('opacity-0', 'max-h-0');
        billing.classList.add('opacity-100', 'max-h-full');
        break;
      case 'single_payment_button':    
        field.value = '1';
        rButton.classList.remove("!border-[#003363]", "dark:!border-[#467af5]")
        sButton.classList.add("!border-[#003363]", "dark:!border-[#467af5]")
        billing.classList.remove('opacity-100', 'max-h-full');
        billing.classList.add('opacity-0', 'max-h-0');                      
        break;
    }
  }

  changeBillingOption(e) {
    e.preventDefault();
    const value = e.target.value;
    const section = document.getElementById('billion_period_entry');
    const field = document.getElementById('duration_days');

    if (value === "custom") {
      section.classList.remove("hidden");
      field.focus();
    } else {
      field.value = value;
      section.classList.add("hidden");
    }
  }

  changeDepositAddress(e) {
    e.preventDefault();
    const section = document.getElementById('deposit_address_entry');
    const field = document.getElementById('deposit_address');
    
    if (e.target.value === "other") {
      section.classList.remove("hidden");
      section.classList.add("flex");
      field.focus();
    } else {
      section.classList.add("hidden");
      section.classList.remove("flex");
      field.value = e.target.value;
    }
  }

  connect() {
    debugLog("Form controller loaded");
    if (this.hasButtonTarget) {
      this.buttonTargets.forEach((button) => {
        button.addEventListener("click", this.buttonClick);
      });
    }
  }

  disconnect() {
    this.buttonTargets.forEach((button) => {
      button.removeEventListener("click", this.buttonClick);
    });    
  }
}
