// debugUtils.js

export function debugLog (...args) {
  if (process.env.PARCEL_DEBUG_MODE === 'true') {
    console.log(...args);
  }
};

export function debugError (...args) {
  if (process.env.PARCEL_DEBUG_MODE === 'true') {
    console.error(...args);
  }
};
