import { Controller } from "@hotwired/stimulus";
import httpService from "../services/HttpService";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["button","form"];

  static values = {
    posturl: String     
  };


  initialize() {
    this.buttonClick = async (e) => {
        e.stopPropagation();
        //let form = document.getElementById(this.formidValue);
        let formData = new FormData(this.formTarget);

        let formObject = {};
        formData.forEach((value, key) => {
          // Handle nested structure for customFieldData
          if (key.startsWith("custom.")) {
              let customKey = key.split(".")[1];
              if (!formObject.customFieldData) {
                  formObject.customFieldData = {};
              }
              formObject.customFieldData[customKey] = value;
          } else {
              // Regular fields
              formObject[key] = value;
          }

      });
        
        debugLog("Form Data: ", formObject);
        debugLog("Post URL: ", this.posturlValue);

        try {
          let res = await httpService.post(
            this.posturlValue,
            formObject
          );
          switch (res.type) {
            case "html":
              Turbo.renderStreamMessage(res.data);
              break;
            case "redirect":
              Turbo.visit(res.data)  
              break;
          }  

        } catch (error) {
          this.dispatch("error", { detail: { content: error.message } });
        }
     
    }
    
  }

  connect() {
    debugLog("Form controller loaded");
    if (this.hasButtonTarget) {
        this.buttonTargets.forEach((button) => {
            button.addEventListener("click", this.buttonClick);
        });
    }
  }

  disconnect() {
    this.buttonTargets.forEach((button) => {
        button.removeEventListener("click", this.buttonClick);
    });    
  }
}
