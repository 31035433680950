import { Controller } from "@hotwired/stimulus";
import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";
import { debugLog } from "../utils";

export default class extends Controller {
  static targets = ["input","textarea","previewbutton"];

  initialize() {
    Coloris.init();
    this.colorSelected = (color, el) => {
      this.updateScriptCode(color)
      this.updatePreview(color)
    }

    this.updatePreview = (newColorValue) => {
      this.previewbuttonTarget.style.backgroundColor = newColorValue;
      this.previewbuttonTarget.style.color = this.getContrastingTextColor(newColorValue);
    }

    this.updateScriptCode = (newColorValue) => {
        // Get the HTML content from the textbox
        var textBoxContent = this.textareaTarget.value;

        // Define a regular expression to match the data-color attribute and its value
        var regex = /(data-color=")[^"]*(")/;

        // Replace the old data-color value with the new one
        var updatedContent = textBoxContent.replace(regex, `$1${newColorValue}$2`);

        // Update the textbox with the new HTML content
        this.textareaTarget.value = updatedContent;
    }
  }

  connect() {
    debugLog("Coloris Controller Connected");
    Coloris.setInstance('.coloris-no-alpha',{
        alpha: false,
        onChange: this.colorSelected
    });    
  }

  open() {

  }

  disconnect() {
    // Close the dialog and revert the color to its original value
    Coloris.close(true);
  }

 getContrastingTextColor(backgroundColor) {
    var color = backgroundColor.charAt(0) === '#' ? backgroundColor.substring(1, 7) : backgroundColor;
    var r = parseInt(color.substring(0, 2), 16); // hex to decimal
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var l = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return l > 0.179 ? 'black' : 'white';
}

}
