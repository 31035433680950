import { Controller } from "@hotwired/stimulus";
import httpService from "../services/HttpService";
import { debugLog } from '../utils';

export default class extends Controller {
  static targets = ["checkbox", "button"];

  initialize() {
    this.disableButton();
    this.checkboxTarget.addEventListener('change', this.handleCheckBoxClick.bind(this));
    this.buttonTarget.addEventListener('click', this.handleJoinProgramButtonClick);
  }

  handleCheckBoxClick() {
    if (this.checkboxTarget.checked) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  async handleJoinProgramButtonClick() {
    await httpService.post('/merchant/affiliate/signup');
  }

  enableButton() {
    this.buttonTarget.classList.remove('bg-light-form-button-disabled-background', 'dark:bg-dark-form-button-disabled-background', 'pointer-events-none');
    this.buttonTarget.classList.add('bg-light-form-button-default-background', 'dark:bg-dark-form-button-default-background');
  }
  
  disableButton() {
    this.buttonTarget.classList.remove('bg-light-form-button-default-background', 'dark:bg-dark-form-button-default-background');
    this.buttonTarget.classList.add('bg-light-form-button-disabled-background', 'dark:bg-dark-form-button-disabled-background', 'pointer-events-none');
  }
}
